:root {
  --dark-color:#141452;
  --light-color:#06c;
}

.App {
  background-color: white; /*#282c34*/
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  font-family: 'Poppins', sans-serif;
  color: #141452;
}

.navbar{
  background-color: white;
  font-size: calc(5px + 2vmin);
  
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  align-items: none;
  justify-content: none;
}

.navbar li{
  float: right;
  display: block;
  color: #141452;
  /* text-align: center; */
  padding: 14px 16px;
  text-decoration: none;
}

.navbarCurrent {
  display: block;
  color: #141452;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.normalLink{
  display: block;
  color: #141452;
  text-align: center;
  text-decoration: none;
}

.nameLink {
  display: block;
  color: #141452;
  text-align: center;
  text-decoration: none;
  padding: 14px 16px;
}

.navbarCurrent {
  color: gray;
}

li a:hover {
  color: gray;
}

.section {
  /* background-color: #f9faff; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 4em;
  /* text-align: center; */
  /* border: 2px solid red; */
}
.sectionInfo {
  width: 35em;
  height: 20em;
  /* align-items: center; */
  /* margin: 20px; */
  /* border: 2px solid red; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectionImage {
  width: 35em;
  height: 20em;
  align-items: center;
  /* margin: 20px; */
  /* border: 2px solid red; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#text {
  text-align: left;
}

#text h4 {
  margin: 1px;
  font-weight: lighter;
  align-items: center;
}
#text ul {
  padding: 0;
}

#footer {
  background-color: #141452;
  padding: 4px;
  color:white;
  text-align: center;
  font-size: calc(2vmin);
}

/* Responsive photo layout from w3schools */
.collageRow {
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
  padding: 0 4px;
}

.collageCol {
  flex: 20%;
  max-width: 25%;
  padding: 0 4px;
}

.collageCol img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

.mobile-option {
  display: none;
}
.mobile-menu {
  display: none;
}



@media (max-width: 950px) {
  /* Responsive layout - makes a two column-layout instead of four columns */
  .collageCol {
    flex: 40%;
    max-width: 50%;
  }
  
  /*Mobile view for nav*/
  .nav-options {
    display: flex;
    width: 100%;
    position: absolute;
    top: 45px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }

  .nav-options.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    flex-direction: column-reverse;
  }

  .menu-icon {
    width: calc(7px + 4vmin);
    height: calc(7px + 4vmin);
    float: right;
    display: block;
    color: #141452;
    text-align: center;
    padding: 14px 16px;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-menu {
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden; 
    align-items: none;
    justify-content: none;
  }

  .sectionImage {
    display: none;
  }
}


